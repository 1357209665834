import React, { useRef } from "react"

import { Layout } from "@components/layout"
import { Partners } from "@components/standard"

const PartnersPage = ({ pageContext, location }) => {
  const { page } = pageContext
  console.log("p", page)
  return (
    <Layout {...page}>
      <Partners />
    </Layout>
  )
}

export default PartnersPage
